import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import {
  pennsylvaniaHero,
  pennsylvaniaRestaurantTypes,
  pennsylvaniaIconTemplate,
  pennsylvaniaLargeFeatures,
  pennsylvaniaWhiteGlove,
  pennsylvaniaXL,
  pennStateSeal,
  // pennsylvaniaTestimonials,
} from "../../data/local/penn-ra";
import ogImage from "../../images/global_assets/og-image.png";
import LocalHero from "../../components/Hero/LocalHero";
import { georgiaTestimonialsData } from "../../data/local/georgia-data";
import LocalSlider from "../../components/ColoradoPortedComps/LocalSlider";
import PartnerLogos from "../../components-v2/Sections/PartnerLogos";

const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LocalRestaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/LocalRest")
);
const Restaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/Rest")
);
const XLArea = loadable(() =>
  import("../../components/ColoradoPortedComps/XLArea")
);
// const TestmonialReviews = loadable(() =>
//   import("../../components/TestimonialReviews/TestmonialReviews")
// );
const whiteGloveImport = "caregiver.png";

const pennsylvania = () => {
  return (
    <Layout
      isLanding
      secondaryNav
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="SpotOn | Pennsylvania Restaurant Association"
        description="SpotOn is providing Pennsylvania restaurants with the tools they need to boost sales and simplify operations with a comprehensive point-of-sale software platform."
        image={`https://spoton.com/${ogImage}`}
      />
      <LocalHero sectionData={pennsylvaniaHero} seal={pennStateSeal} />
      <PartnerLogos />
      <LocalRestaurants data={pennsylvaniaRestaurantTypes} nav="#" />
      <Restaurants
        data={pennsylvaniaIconTemplate}
        subtext="Looking to update and streamline your point-of-sales solution to boost sales? SpotOn has a fully-integrated software platform to help you do just that."
        nav="pos"
      />
      <LocalSlider
        title="What SpotOn customers in Pennsylvania are saying"
        nav="cust"
        testimonialsData={georgiaTestimonialsData}
      />
      <LargeFeatures
        sectionData={pennsylvaniaLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <XLArea data={pennsylvaniaXL} nav="service" />
      <WhiteGlove
        sectionData={pennsylvaniaWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
    </Layout>
  );
};

export default pennsylvania;
